import { useAuthStore } from '../store/store.js';

export default async function connectAsMiddleware(to) {
  const { token } = to.query;

  if (token) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('adminConnectAs');

    document.cookie = `token=; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT`;

    const authStore = useAuthStore();
    authStore.setToken(token);
    authStore.setAdminConnectAs(true);

    document.cookie = `token=${token}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
  }
}
