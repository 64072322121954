import { useAuthStore } from '../store/store.js';

export default async function authMiddleware(to, from, next) {
  const auth = useAuthStore();
  await auth.checkAuthentication();
  if (to.meta.auth && !auth.isAuth) {
    next('/login');
  } else if (to.meta.auth && auth.user.password_update_needed) {
    next({
      path: '/update-password',
      query: { email: auth.user.email },
    });
  } else if (
    to.meta.auth
    && auth.user
    && auth.user.verification_status === 'pending'
    && auth.user.company?.has_employees_number
  ) {
    if (to.meta.validatedAccountRequired) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
}
