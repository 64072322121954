<template>
  <div class="container d-flex flex-row">
    <form
      class="form flex-1 p-6"
      :style="{ '--first-color': firstColor, '--second-color': secondColor }"
      @submit.prevent="sendResetPasswordLink"
    >
      <div class="logo">
        <img
          v-if="logo"
          :src="logo"
          alt="logo"
          class="mt-2"
        >
        <img
          v-else
          src="../assets/images/logo.png"
          alt="logo"
        >
      </div>
      <p class="reset-text ml-2 mt-2 mb-1">
        Mot de passe oublié ? Pas de soucis. Veuillez nous indiquer votre
        adresse e-mail et nous vous enverrons un lien de réinitialisation du mot
        de passe.
      </p>
      <div class="form-group ml-2 mt-2 mb-1">
        <label for="email">E-mail</label>
        <input
          id="email"
          v-model="email"
          class="p-0-5 email-input"
          type="email"
          name="email"
          required
        >
      </div>

      <button
        class="button border ml-2 mt-1 p-1"
        type="submit"
      >
        Recevoir un lien de réinitialisation du mot de passe
      </button>
      <div
        class="login ml-2 mt-2"
        :class="specificSpace ? 'login-specific-space': ''"
      >
        <span>Vous vous en souvenez ? </span>
        <router-link
          to="/login"
          class="button-login p-0-5"
          :class="specificSpace ? 'button-login-specific-space' : ''"
        >
          Connectez-vous
        </router-link>
      </div>
      <div class="decoration" />
    </form>
    <div
      class="main-discover flex-2 d-flex mt-4 justify-content-center"
      :style="{ '--first-color': firstColor, '--second-color': secondColor }"
    >
      <div
        v-if="services.length > 0"
        class="services-container"
      >
        <p class="discover-title">
          Parmi les services mis à votre disposition...
        </p>
        <div
          v-for="(service, index) in services"
          :key="index"
          :class="['services', index === 0 ? 'first-service' : 'second-service mt-1 mb-1']"
          :style="{ zIndex: activeServiceIndex === index ? 10 : 1 }"
          @click="setActiveService(index)"
        >
          <div class="services">
            <div class="container-image">
              <div
                v-if="getMediaType(service) === 'video'"
                class="container-media"
              >
                <video
                  class="video"
                  poster="../assets/images/logo099BlackBackground.png"
                  controls
                >
                  <source
                    :src="getVideoSource(service)"
                    type="video/mp4"
                  >
                </video>
              </div>
              <div
                v-else
                class="container-media"
              >
                <img
                  :src="`${apiBaseMedia}${service.media}`"
                  :alt="service.label"
                >
              </div>
            </div>

            <div class="container-content p-2">
              <div class="container-title">
                {{ service.label }}
              </div>
              <div class="d-flex flex-row">
                <div class="container-description">
                  <p v-html="service.description" />
                </div>
                <div class="container-logo">
                  <img
                    :src="`${apiBaseMedia}${service.logo}`"
                    :alt="service.label"
                    class="h-4"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="discover-title"
      >
        <p>
          Connectez-vous et découvrez tous les services mis à votre
          disposition...
        </p>
      </div>
      <div
        v-if="services.length === 0"
        class="dicover-video"
      />
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import { useToast } from 'vue-toast-notification';
import { forgotPassword, getCompany } from '../services/api.js';
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    FooterComponent,
  },
  data() {
    return {
      email: '',
      firstColor: null,
      secondColor: null,
      specificSpace: false,
      logo: null,
      services: [],
      nb_services: 2,
      activeServiceIndex: null,
      apiBaseMedia: process.env.VUE_APP_BASE_MEDIA_URL,
    };
  },
  async mounted() {
    const currentSubDomain = window.location.hostname.split('.')[0];
    if (currentSubDomain !== process.env.VUE_APP_MAIN_SUB_DOMAIN) {
      try {
        const company = (await getCompany({ subdomain: currentSubDomain, nb_services: this.nb_services })).data;
        this.specificSpace = true;
        this.logo = this.apiBaseMedia + company.logo;
        this.firstColor = company.color_1;
        this.secondColor = company.color_2;
        this.services = company.services;
      } catch (error) {
        this.$router.push({ name: 'NotFound' });
      }
    }
  },
  methods: {
    async sendResetPasswordLink() {
      const toast = useToast();
      try {
        const data = {
          email: this.email,
        };
        await forgotPassword(data);
        toast.success(
          'Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail.',
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenu lors de l'envoi du mail de réinitialisation, veuillez contacter le support.",
        );
        console.error(error);
      }
    },
    setActiveService(index) {
      this.activeServiceIndex = index;
    },
    getMediaType(service) {
      return service.media ? 'media' : 'video';
    },
    getVideoSource(service) {
      return `${this.apiBaseMedia}${service.video_1080 || service.video_720 || service.video_480 || service.video_360 || ''}`;
    },
  },
};
</script>

<style scoped lang="scss">

.services{
  background-color: #fff;
  width: 27rem;
  border-radius: 1rem;
  color: #052f5f;
  position: relative;
  cursor: pointer;

}

.first-service{
  position: relative;
  right: 6rem;
  top: 5rem;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    margin-right: -135px;
    right: 11%;
    top:-82px;
    background-color: var(--first-color);
    z-index: -1;
    }
}

.second-service{
  position: relative;
  bottom: 14rem;
  left: 7rem;
  &::after {
    content: "";
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: var(--second-color);
    z-index: -1;
    left: -130px;
    top: 354px;
    }
}
.container-image{
  height: 100%;
}

.container-title{
  font-size: 24px;
  font-weight: bold;
}

.container-logo img{
  width: 18rem;
  height: 2rem;
  object-fit: contain;
}

.video{
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.logo {
  margin: auto;
}

.decoration{
  background-color: var(--second-color, #f8485e);
  bottom: 0;
  position: absolute;
  left: 0px;
  height: 75px;
  width: 75px;
}

 .form {
   position: relative;
   background-color: #fff;
   &::before {
     content: "";
     position: absolute;
     background-color: var(--first-color, #052f5f);
     height: 120px;
     top: 0;
     left:0;
     width: 120px;
   }

   &::after {
     content: "";
     position: absolute;
     background-color: var(--second-color, #f8485e);
     height: 85px;
     right:-42px;
     top: 0;
     width: 85px;
   }
 }

.form-group label {
  display: block;
  font-weight: bold;
  color: #052f5f;
}

.button {
  max-width: 370px;
  width:100%;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

button:hover {
  background-color: #052f5f;
  color: #fff;
}

.button-login {
  background-color: #f8485e;
  border: #f8485e;
  line-height: 1.5rem;
  color: #fff;
  font-size: 12px;
  &-specific-space {
    background-color: v-bind(firstColor);
    border: v-bind(firstColor);
  }
}

.login {
  color: #f8485e;
  font-size: 13px;
  margin-bottom: 4rem;
  &-specific-space {
    color: v-bind(firstColor)
  }
}

 .reset-text {
  color: #052f5f;
  width: 100%;
  max-width: 355px;
}

 .main-discover {
  width:50vw;
  display: flex;
  flex-direction: column;
  align-items:center;

  .discover-title {
    font-size: 32px;
    color: #052f5f;
    font-weight: 700;
    text-align: center;
    max-width: 500px;
    min-width:300px;
  }

  .dicover-video {
    display: flex;
    justify-content: center;
    position: relative;
    width:70%;
    min-height:270px;
    &::before {
      content: "";
      position: absolute;
      width: 270px;
      height: 270px;
      margin-right:-135px;
      right: 50%;
      background-color: var(--second-color, #f8485e);
    }
    &::after {
      content: "";
      position: absolute;
      width: 120px;
      height: 120px;
      margin-left:75px;
      left: 50%;
      top: 75px;
      background-color:  var(--first-color, #052f5f);
    }
  }
}

.form-group input[type="email"]
 {
  width: 100%;
  max-width: 355px;
  border: none;
  border-bottom: 1px solid #052f5f;
  height:32px;
  font-size: 1em;
}

@media (max-width: 1250px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .first-service,
  .second-service {
    position: static;
    &::before,
    &::after {
      content: none;
    }
  }
}

@media (max-width: 1158px ) {
  .main-discover {
    display: none;
  }

  .container {
    justify-content: center;
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    &::after {
     content: "";
     position: absolute;
     background-color: var(--second-color, #f8485e);
     height: 85px;
     right: 0px;
     top: 0;
     width: 85px;
   }
    .logo{
    width: 40%;
  }
  }

  .form-group{
    width: 400px;
  }

.button {
    margin: 2rem  auto 0;
    display: block;
  }

  .decoration{
  height: 50px;
  width: 50px;
}

}

</style>
