<template>
  <HeaderComponent />

  <BannerComponent />
  <div class="wallet-container">
    <!-- Onglets -->
    <div class="tabs">
      <button
        class="tab-button"
        :class="{ active: activeTab === 'balance' }"
        @click="activeTab = 'balance'"
      >
        Mon solde
      </button>
      <button
        class="tab-button"
        :class="{ active: activeTab === 'transactions' }"
        @click="activeTab = 'transactions'"
      >
        Historique de transaction
      </button>
    </div>

    <!-- Contenu des onglets -->
    <div class="tab-content">
      <!-- Onglet Mon Solde -->
      <div v-if="activeTab === 'balance'">
        <h2>Mon solde</h2>
        <p class="balance">
          {{ balance ?? 0 }} €
        </p>
        <button
          class="button-add-funds"
          @click="showAddFundsModal = true"
        >
          Ajouter des fonds
        </button>
      </div>

      <!-- Onglet Historique de transaction -->
      <div v-if="activeTab === 'transactions'">
        <h2>Historique des transactions</h2>
        <table class="transaction-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Montant</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transaction in transactions"
              :key="transaction.id"
            >
              <td>{{ formatDate(transaction.created_at) }}</td>
              <td>
                <span :class="transaction.type === 'debit' ? 'badge badge-danger' : 'badge badge-success'">
                  {{ transaction.type_label }}
                </span>
              </td>
              <td :class="transaction.type === 'debit' ? 'text-danger' : 'text-success'">
                {{ transaction.type === 'debit' ? '- ' : '+ ' }}{{ transaction.amount }} €
              </td>
              <td>{{ transaction.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddFundsModal
      v-if="showAddFundsModal"
      @close-add-funds-modal="showAddFundsModal = false"
      @on-success-stripe="refreshUserBalance"
    />
  </div>

  <FooterComponent />
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import BannerComponent from '../components/BannerComponent.vue';
import AddFundsModal from '../components/Balance/AddFundsModal.vue';
import { getUserWallet } from '../services/api.js';

export default {
  name: 'WalletPage',
  components: {
    AddFundsModal,
    HeaderComponent,
    FooterComponent,
    BannerComponent,
  },
  data() {
    return {
      balance: 0,
      transactions: [],
      activeTab: 'balance',
      showAddFundsModal: false,
    };
  },
  mounted() {
    this.refreshUserBalance();
  },
  methods: {
    async refreshUserBalance() {
      const wallet = await getUserWallet();
      this.balance = parseFloat(wallet.data.amount).toFixed(2);
      this.transactions = wallet.data.transactions;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('fr-FR');
    },
  },
};
</script>

<style scoped>
.wallet-container {
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: auto auto 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
}

.tab-button.active {
  color: #052f5f;
  border-bottom: 3px solid #052f5f;
}

.tab-content {
  padding: 20px;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.balance {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.button-add-funds {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: #052f5f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.button-add-funds:hover {
  background-color: #041e42;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.transaction-table th {
  background-color: #f4f4f4;
}

.text-danger {
  color: red;
}

.text-success {
  color: green;
}
.transaction-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: #fff;
}

.transaction-table thead {
  background-color: #f8f9fa;
  text-align: left;
}

.transaction-table th {
  padding: 12px;
  border-bottom: 2px solid #dee2e6;
  font-weight: bold;
}

.transaction-table tbody tr {
  border-bottom: 1px solid #dee2e6;
}

.transaction-table td {
  padding: 10px;
}

.transaction-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text-danger {
  color: red;
  font-weight: bold;
}

.text-success {
  color: green;
  font-weight: bold;
}

.badge {
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.badge-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.badge-success {
  background-color: #d4edda;
  color: #155724;
}

</style>
