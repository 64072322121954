<template>
  <div class="price-container">
    <p class="beneficiary-price">
      Prix bénéficiaire :
    </p>
    <p class="price">
      {{ finalPrice }}€
    </p>
    <p
      class="show-details"
      @click="toggleDetails"
    >
      Voir le détail du prix <i :class="iconClass" />
    </p>
    <div
      v-if="showDetails"
      class="price-details"
    >
      <p class="public-price">
        Prix public : <span>{{ service.pvp }}€</span>
      </p>
      <p class="cse-price">
        <span>
          <i
            class="fa-solid fa-circle-info tooltip"
          >
            <span class="tooltiptext font-">Correspond à la part prise en charge par votre CSE</span>
          </i> Subvention CSE :
        </span>
        <span>{{ service.ppc ?? 0 }}€</span>
      </p>
      <p class="due-price">
        Reste à payer : <span>{{ finalPrice }}€</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    finalPrice() {
      return this.service.ppc
        ? +(this.service.pvp - this.service.ppc).toFixed(2)
        : +(this.service.pvp).toFixed(2);
    },
    iconClass() {
      return this.showDetails ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down';
    },
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style>
.beneficiary-price {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #F84458;
}

.public-price,
.cse-price, .due-price {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.price {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  color: #F84458;
}

.show-details {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.public-price {
  font-weight: 400;
  font-size: 14px;
  color: #8F8F8F;
}

.cse-price {
  font-weight: 400;
  font-size: 14px;
  color: black;
}

.due-price {
  font-weight: 400;
  font-size: 14px;
  color: #F84458;
}

.price-details p {
  margin: 5px 0;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  font-weight: lighter;
  font-size: 10px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-family: Poppins, sans-serif;

  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -120px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
