<template>
  <div>
    <!-- Modal to confirm service code request -->
    <GenericModal
      v-if="showGenericModal"
      :title="modalTitle"
      :content="service.redirect_confirmation_information"
      :text-cancel="textCancel"
      :text-confirm="textConfirm"
      :request-is-pending="requestIsPending"
      @confirm="walletCheck"
      @cancel="cancelRequest"
    />

    <!-- Modal to display survey questions -->
    <SurveyModal
      v-if="showSurveyModal"
      :survey="activeSurvey"
      :current-question-index="currentQuestionIndex"
      :final-button-text="'Obtenir mon code'"
      @survey-completed="submitFinalRequest"
      @cancel-survey="closeSurveyModal"
    />
    <VerificationPurchaseModal
      v-if="showVerificationPurchaseModal"
      @close-verification-purchase-modal="closeVerificationPurchaseModal"
      @continue-purchase="confirmRequest"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { useToast } from 'vue-toast-notification';
import GenericModal from '../../Utils/GenericModal.vue';
import SurveyModal from '../../7/SurveyModal.vue';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import {
  sendRequestServiceTypeCode,
  fetchActiveSurvey,
} from '../../../services/api.js';
import VerificationPurchaseModal from '../../Utils/VerificationPurchaseModal.vue';
import { useAuthStore } from '../../../store/store.js';

export default {
  components: {
    VerificationPurchaseModal,
    GenericModal,
    SurveyModal,
  },
  props: {
    service: {
      type: Object,
      default: null,
    },
    serviceId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['close-code-modal', 'request-code-validated', 'insufficient-wallet-amount'],
  data() {
    return {
      modalTitle: 'Obtenir votre code',
      textCancel: 'Non',
      textConfirm: 'Oui, je confirme',
      requestIsPending: false,
      showSurveyModal: false,
      showGenericModal: true,
      activeSurvey: null,
      currentQuestionIndex: 0,
      showVerificationPurchaseModal: false,
    };
  },
  methods: {
    closeVerificationPurchaseModal() {
      this.showVerificationPurchaseModal = false;
      this.$emit('close-code-modal');
    },
    walletCheck() {
      this.showGenericModal = false;
      if (useAuthStore().user.company.user_wallet_enabled) {
        this.showVerificationPurchaseModal = true;
      } else {
        this.confirmRequest();
      }
    },
    async confirmRequest() {
      try {
        const surveyResponse = await fetchActiveSurvey();

        if (surveyResponse.data.survey) {
          if (surveyResponse?.success && surveyResponse.data) {
            const {
              survey,
              answeredCount,
              isCompleted,
            } = surveyResponse.data;
            if (!isCompleted) {
              this.activeSurvey = survey;
              this.currentQuestionIndex = answeredCount;
              this.showSurveyModal = true;
              return;
            }
          }
        }

        await this.submitCodeRequest();
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async submitCodeRequest() {
      this.requestIsPending = true;
      try {
        const requestCodeResponse = await sendRequestServiceTypeCode(this.serviceId);
        const toast = useToast();
        if (requestCodeResponse?.data.success) {
          toast.success('Code attribué avec succès !');
          this.$emit('request-code-validated');
        } else {
          toast.error('Erreur lors de la demande de votre code.');
        }
      } catch (error) {
        let errorMessage = '';
        if (error.response.status === 402) {
          this.$emit('insufficient-wallet-amount');
          errorMessage = 'Erreur lors du paiement';
        } else {
          errorMessage = 'Erreur lors de la demande du code';
        }
        Sentry.captureException(error);
        useToast()
          .error(errorMessage);
      } finally {
        this.requestIsPending = false;
        this.$emit('close-code-modal');
      }
    },

    submitFinalRequest() {
      this.submitCodeRequest();
    },

    cancelRequest() {
      this.$emit('close-code-modal');
    },

    closeSurveyModal() {
      this.showSurveyModal = false;
      this.showGenericModal = true;
    },
  },
};
</script>

<style scoped></style>
