<template>
  <teleport to="body">
    <div
      class="modal-overlay funds-form"
    >
      <div
        v-if="!showCheckout"
        class="modal-content-container"
      >
        <div class="modal-title">
          Ajouter des fonds
        </div>

        <div class="modal-content d-flex justify-content-center align-items-center">
          <input
            v-model="amount"
            type="number"
            step="10"
            min="10"
            max="1000"
            class="styled-input"
            @change="validateAmount"
          >
        </div>

        <div class="modal-actions">
          <button
            class="button-home-redirection p-1 mr-2"
            @click="cancel"
          >
            Annuler
          </button>
          <button
            class="button-home-redirection p-1"
            @click="loadStripeCheckout"
          >
            Ajouter
          </button>
        </div>
      </div>
      <div
        class="d-flex flex-column"
      >
        <div
          class="progress-bar-container"
          style="visibility: hidden"
        >
          <div
            ref="progressBar"
            class="progress-bar"
          />
        </div>
        <div
          v-if="showCancelButton"
          style="display: flex;justify-content: end;align-items: center; background-color: white"
        >
          <div
            class="p-1 cancel-checkout-button"
            @click="cancelStripeCheckout"
          >
            X
          </div>
        </div>
        <div
          id="checkout"
          style="width: 1000px"
        />
      </div>
    </div>
  </teleport>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import { loadStripe } from '@stripe/stripe-js';
import { createStripeCheckout } from '../../services/api.js';

export default {
  emits: ['close-add-funds-modal', 'on-success-stripe'],
  data() {
    return {
      amount: 10,
      showCheckout: false,
      showCancelButton: false,
      showProgressBar: false,
      checkout: null,
      sessionId: null,
    };
  },
  mounted() {
  },
  methods: {
    cancel() {
      this.$emit('close-add-funds-modal');
    },
    async loadStripeCheckout() {
      this.validateAmount();

      const { clientSecret, sessionId } = await createStripeCheckout({
        amount: this.amount,
      });

      this.sessionId = sessionId;
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const checkout = await stripe.initEmbeddedCheckout(
        {
          clientSecret,
          onComplete: this.completePayment,
        },
      );
      this.checkout = checkout;

      this.showCheckout = true;
      checkout.mount('#checkout');
      this.showCancelButton = true;
    },
    validateAmount() {
      if (!Number.isInteger(this.amount)) {
        this.amount = 10;
      }

      this.amount = Math.round(this.amount / 10) * 10;
    },
    async completePayment() {
      this.showCancelButton = false;
      this.showProgressBar = true;
      this.startProgressBar(3);
      setTimeout(async () => {
        this.checkout.destroy();
        const toast = useToast();
        toast.success(`Votre compte a bien été crédité du montant de ${this.amount}€`);
        this.$emit('on-success-stripe');
        this.$emit('close-add-funds-modal');
      }, '3000');
    },
    cancelStripeCheckout() {
      this.checkout.destroy();
      this.$emit('close-add-funds-modal');
    },
    startProgressBar(duration) {
      const { progressBar } = this.$refs;
      if (progressBar) {
        progressBar.style.visibility = 'visible';
        progressBar.style.width = '100%';
        progressBar.style.transitionDuration = `${duration}s`;
        progressBar.style.width = '0%';
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  min-width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #052f5f;
}

.modal-content {
  margin-bottom: 15px;
}

.styled-input {
  width: 80%;
  padding: 10px;
  font-size: 1.2rem;
  text-align: center;
  border: 2px solid #052f5f;
  border-radius: 5px;
  outline: none;
}

.styled-input:focus {
  border-color: #d9534f;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #052f5f;
  transition: width linear;
}
.cancel-checkout-button{
  cursor: pointer;
  background-color: #052f5f;
  color: white;
}
.cancel-checkout-button:hover{
  background-color: #36587E;
}
</style>
