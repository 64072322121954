<template>
  <div>
    <div v-if="service.status === 'PENDING_REQUEST'">
      Votre demande est en attente de validation
    </div>
    <div v-else-if="service.status === 'CODE_IN_USE'">
      <div
        v-if="service.can_be_requested_at"
        class="code-with-expiration-date"
      >
        Ce lien est toujours en cours de validité, vous pouvez demander un
        nouveau lien pour ce service le :
        {{ formatDate(service.can_be_requested_at) }}
        <button
          v-if="service.link"
          type="button"
          class="button-home-redirection p-1"
          @click="openServiceAccessLink(service.link)"
        >
          Votre lien d’accès vers le service
        </button>
      </div>
      <div
        v-else
        class="code-input"
      >
        <button
          v-if="service.link"
          type="button"
          class="button-home-redirection p-1"
          @click="openServiceAccessLink(service.link)"
        >
          Votre lien d’accès vers le service
        </button>
      </div>
      <br>
      <div v-if="service.access_link_activation">
        <i
          class="fa-regular fa-copy cursor"
          @click="copyCode()"
        />
        <a
          :href="service.access_link_activation"
          target="_blank"
          style="color: #213054"
        >
          Comment utiliser mon code
          <b>{{ removeLineBreaks(service.label) }}</b> ?
        </a>
      </div>
    </div>
    <div v-else-if="service.status === 'MUST_WAIT_CODE_FINISH'">
      Prochaine demande possible le
      {{ formatDate(service.can_be_requested_at) }}
    </div>
    <div v-else-if="service.status === 'MUST_REQUEST_CODE'">
      <button
        v-if="!service.sms_enabled"
        class="button-home-redirection p-1"
        @click="openConfirmationModal(service.id, service)"
      >
        Obtenir mon lien d'accès
      </button>
      <button
        v-else
        class="button-home-redirection p-1"
        @click="openVerificationModal(service.id , service)"
      >
        Obtenir mon lien d'accès
      </button>
    </div>
    <SmsVerificationModal
      v-if="showVerificationModal"
      :service-id="service.id"
      @verification-sms-success="
        $emit('open-confirmation-modal-access-link', service.id, service)
      "
      @resend-code="openVerificationModal(service.id, service)"
      @too-many-attempts="useToast().error('Trop de mauvaises tentatives')"
      @close-verification-service-modal="showVerificationModal = false"
    />
  </div>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import SmsVerificationModal from '../Code/SmsVerificationModal.vue';
import { sendSmsCodeForServiceAccess } from '../../../services/api.js';

export default {
  components: {
    SmsVerificationModal,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-confirmation-modal-access-link'],
  data() {
    return {
      showVerificationModal: false,
    };
  },
  methods: {
    useToast,
    openConfirmationModal(serviceId) {
      this.$emit('open-confirmation-modal-access-link', serviceId, this.service);
    },
    openServiceAccessLink(link) {
      window.open(link, '_blank');
    },
    async openVerificationModal(serviceId) {
      const toast = useToast();
      try {
        const response = await sendSmsCodeForServiceAccess(serviceId);
        if (response.success) {
          this.showVerificationModal = true;
          toast.success('Le code de vérification a bien été envoyé.');
        } else {
          toast.error(
            response.message,
          );
        }
      } catch (error) {
        toast.error(
          error.response.data.message,
        );
      }
    },
    removeLineBreaks(label) {
      return label.replace(/<br\s*\/?>/gi, ' ');
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('fr-FR');
    },
    copyCode() {
      const copyInput = this.$refs.accessCode;
      copyInput.disabled = false;
      copyInput.select();
      document.execCommand('copy');
      copyInput.disabled = true;
    },
  },
};
</script>
<style scoped>

.code-with-expiration-date{
  display: flex;
  flex-direction: column;
  width: 320px;
  color: #213054;
}

.code-input {
  flex: 1;
}

body input{
  border-bottom: 0 solid #052f5f !important;
}
.code-input input {
  width: 100%;
  min-width: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.code-with-expiration-date input{
  color: #213054;
  font-weight: bold;
  border: 2px solid #213054 !important;
}

.cursor {
  cursor: pointer;
}
</style>
