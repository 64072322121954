<template>
  <teleport to="body">
    <div class="modal-overlay">
      <div class="modal-content-container">
        <div class="modal-title">
          Confirmation du paiement
        </div>
        <div class="modal-content">
          Voulez-vous utiliser le solde disponible dans votre porte-monnaie pour compléter la prise en charge du CSE ?
        </div>
        <div class="modal-actions">
          <button
            class="button-home-redirection mr-2"
            @click="closeVerificationPurchaseModal"
          >
            Annuler
          </button>
          <button
            class="button-home-redirection"
            @click="continuePurchase"
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: 'VerificationPurchaseModal',
  emits: ['close-verification-purchase-modal', 'continue-purchase'],
  methods: {
    continuePurchase() {
      this.$emit('continue-purchase');
    },
    closeVerificationPurchaseModal() {
      this.$emit('close-verification-purchase-modal');
    },
  },
};
</script>
  <style lang="scss" scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content-container {
    width: 37%;
    background-color: #F7F7F7;
    top: 50%;
    left: 50%;
    padding: 20px 50px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .modal-title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #0f2e69;
  }

  .modal-content {
    margin-bottom: 20px;
    font-size: 16px;
    color: #052f5f;

  }

  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin: 0px 60px;
  }

  .button-home-redirection {
       background-color: #fff;
       color: #052f5f;
       cursor: pointer;
       font-size: 18px;
       font-weight: bold;
       justify-content: flex-end;
       padding: 15px 30px;     }

     .button-home-redirection:hover {
       background-color: #052f5f;
       color: #fff;
       cursor: pointer;
       font-size: 18px;
       font-weight: bold;
       justify-content: flex-end;
     }

     .button-delete{
      background-color: #F7F7F7;
      color: #dc3545;
      border: 2px solid #dc3545;
      cursor: pointer;
       font-size: 18px;
       font-weight: bold;
       padding: 15px 30px;
    }

    .button-delete:hover{
      background-color: #dc3545;
      color: #F7F7F7;
      cursor: pointer;
    }
  </style>
